<template>
  <div v-if="item && article">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onReturnBorrow"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Rendre"
      :ok-disabled="!isValid()"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-rotate-left"></i> Rendre</b>
      </template>
      <b-row v-if="error">
        <b-col>
          <div class="error-text">{{ error }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="article-group"
            label="Jeu"
            label-for="article"
          >
            <b-form-input
              id="article"
              type="text"
              disabled
              v-model="article.name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="item-group"
            label="Exemplaire"
            label-for="item"
          >
            <b-form-input
              id="item"
              type="text"
              disabled
              v-model="item.reference"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-form-group
            id="entity-group"
            label="Famille"
            label-for="entity"
          >
            <b-form-input
              id="entity"
              type="text"
              disabled
              :value="entity ? entity.name : ''"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="borrowedOn-group"
            label="Date d'emprunt"
            label-for="borrowedOn"
          >
            <b-form-input
              id="borrowedOn"
              type="date"
              disabled
              v-model="borrowedOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="returnExpectedOn-group"
            label="À rendre le"
            label-for="returnExpectedOn"
          >
            <b-form-input
              id="returnExpectedOn"
              type="date"
              disabled
              v-model="returnExpectedOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col v-if="penaltiesOn">
          <b-form-group
            id="penaltiesOn-group"
            label="Pénalités à partir du"
            label-for="penaltiesOn"
          >
            <b-form-input
              id="penaltiesOn"
              type="date"
              disabled
              v-model="penaltiesOn"
              :class="hasPenalties ? 'has-penalties' : 'no-penalties'"
            ></b-form-input>
          </b-form-group>
          <div v-if="hasPenalties">
            <b-form-group
              id="penaltiesAmount-group"
              label="Montant"
              label-for="penaltiesAmount"
              description="Saisir le montant de la pénalité"
            >
              <decimal-input id="penaltiesAmount" v-model="penaltiesAmount">
              </decimal-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-form-group
            id="returnedOn-group"
            label="Rendu le"
            label-for="returnedOn"
            description="Saisir la date retour"
          >
            <b-form-input
              id="returnedOn"
              type="date"
              required
              v-model="returnedOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeBorrowing } from '@/types/store'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'

export default {
  name: 'ReturnBorrowModal',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
  },
  props: {
    article: Object,
    item: Object,
    borrowing: Object,
  },
  data() {
    return {
      borrowedOn: null,
      returnExpectedOn: null,
      penaltiesOn: null,
      hasPenalties: false,
      penaltiesAmount: 0,
      returnedOn: null,
      entity: null,
      error: '',
    }
  },
  computed: {
    modalId() {
      return 'bv-modal-return-borrow-article' + this.item.id
    },
  },
  watch: {
    item() {
      this.init()
    },
    borrowing() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      this.returnedOn = moment().format('YYYY-MM-DD')
      if (this.borrowing) {
        this.entity = this.borrowing.entity
        this.borrowedOn = moment(this.borrowing.borrowedOn).format('YYYY-MM-DD')
        this.returnExpectedOn = moment(this.borrowing.returnExpectedOn).format('YYYY-MM-DD')
        if (this.borrowing.penaltiesOn) {
          this.penaltiesOn = moment(this.borrowing.penaltiesOn).format('YYYY-MM-DD')
          this.hasPenalties = this.borrowing.hasPenalties()
          this.penaltiesAmount = 0
        } else {
          this.penaltiesOn = null
          this.penaltiesAmount = 0
        }
      }
    },
    isValid() {
      return this.returnedOn
    },
    async onReturnBorrow() {
      this.error = ''
      try {
        let url = '/store/api/return-borrow/'
        const backendApi = new BackendApi('post', url)
        const data = {
          item: this.item.id,
          returned_on: moment(this.returnedOn).format('YYYY-MM-DD'),
          penalties_amount: this.penaltiesAmount,
        }
        const resp = await backendApi.callApi(data)
        const borrowing = await makeBorrowing(resp.data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', borrowing)
        await this.addSuccess('Le retour a été enregistré')
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
  },
}
</script>

<style scoped>
input.has-penalties {
  background: #ff9d82;
}
input.no-penalties {
  background: #c8eec8;
}
</style>
