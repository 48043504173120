<template>
  <div v-if="item && article">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onCancelBorrow"
      ok-variant="primary"
      cancel-title="Annuler l'opération"
      ok-title="Annuler le prêt"
      :ok-disabled="!isValid()"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-rotate-left"></i> Annuler le prêt</b>
      </template>
      <b-row v-if="error">
        <b-col>
          <div class="error-text">{{ error }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="article-group"
            label="Jeu"
            label-for="article"
          >
            <b-form-input
              id="article"
              type="text"
              disabled
              v-model="article.name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="item-group"
            label="Exemplaire"
            label-for="item"
          >
            <b-form-input
              id="item"
              type="text"
              disabled
              v-model="item.reference"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-form-group
            id="entity-group"
            label="Famille"
            label-for="entity"
          >
            <b-form-input
              id="entity"
              type="text"
              disabled
              :value="entity ? entity.name : ''"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="borrowedOn-group"
            label="Date d'emprunt"
            label-for="borrowedOn"
          >
            <b-form-input
              id="borrowedOn"
              type="date"
              disabled
              v-model="borrowedOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeBorrowing } from '@/types/store'
import { mapActions } from 'vuex'

export default {
  name: 'CancelBorrowModal',
  mixins: [BackendMixin],
  components: {
  },
  props: {
    article: Object,
    item: Object,
    borrowing: Object,
  },
  data() {
    return {
      borrowedOn: null,
      returnExpectedOn: null,
      entity: null,
      error: '',
    }
  },
  computed: {
    modalId() {
      return 'bv-modal-cancel-borrow-article' + this.item.id
    },
  },
  watch: {
    item() {
      this.init()
    },
    borrowing() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      this.returnedOn = moment().format('YYYY-MM-DD')
      if (this.borrowing) {
        this.entity = this.borrowing.entity
        this.borrowedOn = moment(this.borrowing.borrowedOn).format('YYYY-MM-DD')
      }
    },
    isValid() {
      return true
    },
    async onCancelBorrow() {
      this.error = ''
      try {
        let url = '/store/api/cancel-borrow/'
        const backendApi = new BackendApi('post', url)
        const data = {
          item: this.item.id,
        }
        const resp = await backendApi.callApi(data)
        const borrowing = await makeBorrowing(resp.data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', borrowing)
        await this.addSuccess('L\'emprunt a été annulé')
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
  },
}
</script>

<style scoped>
</style>
