<template>
  <span class="upload-image" v-if="!perm || hasPerm(perm)">
    <a href @click.prevent="showModal" class="small-text">
      <i class="fa fa-upload"></i> Télécharger
    </a>
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isUploadDisabled()"
      @ok.prevent="onUpload"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <div>
          <i class="fa fa-upload"></i>
          Télécharger une image
        </div>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="the-title">
        <span v-if="title">{{ title }}</span>
        <span v-else>Mettre à jour une image</span>
      </div>
      <b-row v-if="hasFile">
        <b-col>
          <b-form-group
            id="reset-file-group"
            label-for="reset-file"
            description="Si coché, le fichier sera supprimé"
          >
            <b-checkbox id="reset-file" v-model="resetFile"><b>Supprimer le fichier</b></b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!doDeleteFile">
        <b-col>
          <b-form-group
            id="upload-file-group"
            label="Fichier"
            label-for="file"
            :description="'Joindre le scan de la fiche de liaison signée. Taille maximum ' + maxSizeStr"
          >
            <input type="file" id="file" ref="file" class="form-control" @change="onFileUpload()" />
          </b-form-group>
          <div class="file-detail" v-if="file">
            <b-row>
              <b-col>Date: {{ file.lastModified | tsToDate }}</b-col>
              <b-col class="text-right">
                Taille: {{ file.size | fileSize }}<br />
                <div v-if="file.size >= maxSize" class="error-text">
                  Le fichier dépasse la limite autorisée de {{ maxSize | fileSize }}
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { fileSize } from '@/filters/texts'

export default {
  name: 'upload-image',
  mixins: [BackendMixin],
  props: {
    perm: String,
    url: String,
    title: String,
    modalId: String,
    hasFile: Boolean,
  },
  data() {
    return {
      errorText: '',
      file: null,
      maxSize: 1024 * 1024 / 2,
      resetFile: false,
    }
  },
  computed: {
    maxSizeStr() {
      return fileSize(this.maxSize)
    },
    doDeleteFile() {
      return (this.resetFile && this.hasFile)
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.modalId)
    },
    isUploadDisabled() {
      if (this.hasFile && this.resetFile) {
        return false
      }
      return (this.file === null) || (this.file.size >= this.maxSize)
    },
    async onUpload() {
      this.errorText = ''
      if (!this.isUploadDisabled()) {
        try {
          let backendApi = new BackendApi('post', this.url)
          let data = new FormData()
          if (this.doDeleteFile) {
            data.append('has_image', '0')
            data.append('image', '')
          } else {
            data.append('has_image', '1')
            data.append('image', this.file)
          }
          const resp = await backendApi.callApi(data, 'multipart/form-data')
          this.$bvModal.hide(this.modalId)
          this.$emit('uploaded', { image: resp.data.image, })
          if (this.doDeleteFile) {
            await this.addSuccess('Le fichier a été supprimé')
          } else if (this.hasFile) {
            await this.addSuccess('Le fichier a été remplacé')
          } else {
            await this.addSuccess('Le fichier a été ajouté')
          }
        } catch (err) {
          this.errorText = this.getErrorText(err)
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onFileUpload() {
      this.file = this.$refs.file.files[0]
    },
  },
  mounted() {
  },
}
</script>
<style scoped lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .the-title {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    font-weight: bold;
  }
</style>
