<template>
  <div>
    <div v-if="article">
      <ul class="article-detail">
        <li class="article-title">
          <b-row>
            <b-col>
              <b>{{ article.name }}</b>
            </b-col>
            <b-col cols="4" class="text-right">
              <router-link :to="getLinkToArticle(article)" v-if="link" class="btn btn-secondary btn-xs">
                <i class="fa fa-circle-arrow-right"></i> Voir
              </router-link>
            </b-col>
          </b-row>
        </li>
        <li>
          <img :src="article.imageUrl" class="img-fluid" v-if="article.imageUrl" />
          <div v-if="!article.imageUrl" class="empty-text">Pas d'image</div>
          <upload-image
            perm="store.change_article"
            :url="'/store/api/upload-article-image/' + article.id + '/'"
            :title="'Ajouter une image pour ' + article.name"
            :has-file="!!article.imageUrl"
            modal-id="upload-store-article-image"
            @uploaded="article.imageUrl = $event.image"
          ></upload-image>
        </li>
        <li v-if="article.category.id">
          <b><i class="fa fa-bookmark"></i> Catégorie<br /></b>
          {{ article.category.name }}
        </li>
        <li v-if="article.editionYear">
          <b><i class="fa fa-calendar-alt"></i> Année d'édition<br /></b>
          {{ article.editionYear }}
        </li>
        <li v-if="article.type.id">
          <b><i class="fa fa-book-bookmark"></i> Type<br /></b>
          {{ article.type.name }}
        </li>
        <li v-if="article.location.id">
          <b><i class="fa fa-magnifying-glass-location"></i> Emplacement<br /></b>
          {{ article.location.name }}
        </li>
        <li v-if="article.classification.id">
          <b><i class="fa fa-mortar-board"></i> Classification<br /></b>
          {{ article.classification.name }}
        </li>
        <li v-if="article.usage1">
          <b><i class="fa fa-dice-four"></i> Nb de joueurs<br /></b>
          {{ article.usage1 }}
        </li>
        <li v-if="article.usage2">
          <b><i class="fa fa-hourglass-half"></i> Durée<br /></b>
          {{ article.usage2 }}
        </li>
        <li v-if="article.usage3">
          <b><i class="fa fa-bullseye"></i> Objectif<br /></b>
          {{ article.usage3 }}
        </li>
        <li v-if="article.theme">
          <b><i class="fa fa-theater-masks"></i> Thème<br /></b>
          {{ article.theme }}
        </li>
        <li v-if="!isAllShown(article) && hasMore(article)">
          <a href @click.prevent="showMore()">
            <i class="fa fa-ellipsis-h"></i> Voir plus
          </a>
        </li>
        <li v-if="article.editor.id && isAllShown(article)">
          <b><i class="fa fa-pen-ruler"></i> Éditeur<br /></b>
          {{ article.editor.name }}
        </li>
        <li v-if="article.distributor.id && isAllShown(article)">
          <b><i class="fa fa-boxes-packing"></i> Distributeur<br /></b>
          {{ article.distributor.name }}
        </li>
        <li v-if="article.link && isAllShown(article)">
          <b><i class="fa fa-link"></i> Site Internet<br /></b>
          <a :href="article.link" target="_blank">{{ article.link }}</a>
        </li>
        <li v-if="article.description && isAllShown(article)">
          <b><i class="fa fa-list"></i> Description<br /></b>
          {{ article.description }}
        </li>
        <li v-if="article.contents.length && isAllShown(article)">
          <b><i class="fa fa-box-open"></i> Contenu<br /></b>
          <div v-for="content of article.contents" :key="content.id" class="small-text">
            {{ content.name }}
            <div v-if="content.description">{{ content.description }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import UploadImage from '@/components/Controls/UploadImage.vue'

export default {
  name: 'ArticleInfo',
  mixins: [BackendMixin],
  components: {
    UploadImage,
  },
  props: {
    article: Object,
    link: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
  },
  watch: {
    article() {
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    getLinkToArticle(article) {
      return { name: 'store-article-detail', params: { articleId: '' + article.id, }, }
    },
    isAllShown(article) {
      return article && this.showAll
    },
    hasMore(article) {
      return article.contents.length || article.description || article.editor.id ||
        article.distributor.id || article.link
    },
    showMore() {
      this.showAll = true
    },
  },
}
</script>

<style scoped>
  ul.article-detail {
    list-style: none;
    margin: 0;
    padding: 5px;
    background: #eee;
  }

  ul.article-detail li.article-title {
    background: #454851;
    color: #fff;
  }

  ul.article-detail li {
    padding: 4px;
    margin-bottom: 4px;
    border-bottom: solid 1px #ccc;
  }

  ul.article-detail li:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
  .article-title {
    padding: 5px;
    background: #e0e0e0;
  }
  ul.article-detail ul.sub-ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul.article-detail ul.sub-ul li {
    margin-left: 20px;
    padding: 2px;
  }
</style>
