
<template>
  <div v-if="item && article">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onBorrow"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Emprunter"
      :ok-disabled="!isValid()"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-rotate-right"></i> Emprunter</b>
      </template>
      <b-row v-if="error">
        <b-col>
          <div class="error-text">{{ error }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="entity-group"
            label="Famille"
            label-for="entity"
          >
            <b-form-input
              id="entity"
              type="text"
              disabled
              :value="entity.name + ': ' + entity.members"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="article-group"
            label="Jeu"
            label-for="article"
            :description="article.type.name"
          >
            <b-form-input
              id="article"
              type="text"
              disabled
              v-model="article.name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="item-group"
            label="Exemplaire"
            label-for="item"
          >
            <b-form-input
              id="item"
              type="text"
              disabled
              v-model="item.reference"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="borrowedOn-group"
            label="Date de l'emprunt"
            label-for="borrowedOn"
          >
            <b-form-input
              id="borrowedOn"
              type="date"
              required
              v-model="borrowedOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="returnExpectedOn-group"
            label="À rendre le"
            label-for="returnExpectedOn"
          >
            <b-form-input
              id="returnExpectedOn"
              type="date"
              required
              v-model="returnExpectedOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="penaltiesOn-group"
            label="Pénalités à partir du"
            label-for="penaltiesOn"
          >
            <b-form-input
              id="penaltiesOn"
              type="date"
              v-model="penaltiesOn"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="adhesion-group"
            label="Adhésion"
            label-for="adhesion"
          >
            <b-form-input
              id="adhesion"
              type="text"
              disabled
              :value="adhesion ? adhesion.getLabel() : 'Aucune adhésion'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="entityType-group"
            label="Type d'emprunteur"
            label-for="entityType"
          >
            <b-select v-model="selectedEntityType" id="entityType" :disabled="!!adhesion">
              <b-form-select-option
                :value="entityType"
                v-for="entityType of entityTypes"
                :key="entityType.id"
              >
                {{ entityType.name }}
              </b-form-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="price-group"
            label="Prix"
            label-for="price"
          >
            <decimal-input v-model="price" id="price"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeBorrowing, makeStoreAdhesion } from '@/types/store'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'

export default {
  name: 'BorrowModal',
  mixins: [BackendMixin],
  components: {
    DecimalInput,
  },
  props: {
    article: Object,
    item: Object,
    entity: Object,
    adhesion: Object,
    entityTypes: Array,
    initialEntityType: Object,
  },
  data() {
    return {
      borrowedOn: null,
      returnExpectedOn: null,
      penaltiesOn: null,
      error: '',
      price: 0,
      selectedEntityType: null,
    }
  },
  computed: {
    modalId() {
      return 'bv-modal-borrow-article' + this.item.id
    },
  },
  watch: {
    item() {
      this.init()
    },
    selectedEntityType() {
      this.price = 0
      if (this.selectedEntityType && !this.adhesion) {
        const priceIndex = this.selectedEntityType.priceIndex
        if ((priceIndex > 0) && (priceIndex <= 3)) {
          const priceField = 'price' + priceIndex
          this.price = this.article.type[priceField] || 0
        }
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      this.borrowedOn = moment().format('YYYY-MM-DD')
      let delay = 21
      let penalties = 0
      if (this.adhesion) {
        delay = this.adhesion.adhesionType.borrowingDuration
        penalties = this.adhesion.adhesionType.borrowingPenalties
      }
      this.returnExpectedOn = (moment().add(delay, 'days')).format('YYYY-MM-DD')
      if (penalties) {
        this.penaltiesOn = (moment().add(penalties, 'days')).format('YYYY-MM-DD')
      } else {
        this.penaltiesOn = null
      }
      if (this.initialEntityType) {
        const index = this.entityTypes.map(elt => elt.id).indexOf(this.initialEntityType.id)
        if (index >= 0) {
          this.selectedEntityType = this.entityTypes[index]
        }
      }
    },
    isValid() {
      if (!this.adhesion && !this.selectedEntityType) {
        return false
      }
      return this.entity && this.borrowedOn && this.returnExpectedOn
    },
    async onBorrow() {
      this.error = ''
      try {
        let url = '/store/api/borrow/'
        const backendApi = new BackendApi('post', url)
        const data = {
          item: this.item.id,
          entity: this.entity.id,
          borrowed_on: moment(this.borrowedOn).format('YYYY-MM-DD'),
          return_expected_on: moment(this.returnExpectedOn).format('YYYY-MM-DD'),
          penalties_on: this.penaltiesOn ? moment(this.penaltiesOn).format('YYYY-MM-DD') : null,
          entity_type: this.selectedEntityType ? this.selectedEntityType.id : 0,
          adhesion: this.adhesion ? this.adhesion.id : 0,
          price: this.price,
        }
        const resp = await backendApi.callApi(data)
        const borrowing = await makeBorrowing(resp.data)
        await this.addSuccess('L\'emprunt a été enregistré')
        this.$emit('done', borrowing)
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
  },
}
</script>

<style scoped>
.select-family {
  padding: 2px 5px;
  background: #f8b200;
  font-weight: bold;
}
.family-selected {
  padding: 2px 5px;
  background: #f2f2a2;
  font-weight: bold;
}
</style>
